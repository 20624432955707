import React from 'react';
import { Button, Card, Layout } from 'antd';
import { Link } from 'react-router-dom';

const { Header, Content } = Layout;

const DonateOption3 = () => {
  return (
    <Layout>
      <Header style={{ background: '#fff', padding: '0 16px' }}>
        <Link to="/">Back to Home</Link>
      </Header>
      <Content className="min-h-screen flex items-center justify-center bg-gray-100">
        <Card
          title="Donation Option 3"
          style={{ width: 300 }}
        >
          <p>Make a custom donation</p>
          <p>Choose your own donation amount to support our project in a way that works best for you.</p>
          <Button type="primary" onClick={() => alert("Thank you for your custom donation!")}>
            Choose Amount
          </Button>
        </Card>
      </Content>
    </Layout>
  );
};

export default DonateOption3;
