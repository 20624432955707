import React from 'react';
import { Button, Card, Layout, Image } from 'antd';
import { Link } from 'react-router-dom';

const { Header, Content } = Layout;

const DonateOption1 = () => {
  return (
    <Layout>
      <Header style={{ background: '#fff', padding: '0 16px' }}>
        <Link to="/">Back to Home</Link>
      </Header>
      <Content className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
        <Card
          title="Donation Option 1"
          style={{ maxWidth: '600px', width: '100%' }}
          cover={
            <img
              src="/IMG_0975.JPG"
              alt="Donation"
              className="w-full h-64 object-cover"
            />
          }
        >
          <div className="space-y-4">
            <p className="text-lg">Support our project with a one-time donation</p>
            <p className="text-gray-600">Your contribution helps us continue our mission and improve our services.</p>
            <Button 
              type="primary" 
              size="large"
              block
              onClick={() => alert("Thank you for your donation!")}
            >
              Donate $10
            </Button>
          </div>
        </Card>
      </Content>
    </Layout>
  );
};

export default DonateOption1;
