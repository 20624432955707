import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { message } from 'antd'

import TYPES from './DataTypes'
import Header from './Header'
import DataSection from './DataSection'
import GithubFetcher from '../scripts/GithubFetcher'
import { updateState } from '../actions'

const GITHUB_API_TOKEN = process.env.REACT_APP_GITHUB_API_TOKEN

class GithubStatistics extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      repos: [],
      input: undefined,
      suggestions: [],
      testingRepo: false,
      deleteRepo: '',
    }

    var t = btoa(GITHUB_API_TOKEN)
    this.fetcher = new GithubFetcher(t)
    this.props.updateState("githubApiToken", t)

    this.search = _.debounce(
      this.fetcher.searchRepository,
      300,
      { leading: false, trailing: true }
    ).bind(this)
  }

  componentDidMount() {
    this.loadReposFromUrlOrStorage()
  }

  loadReposFromUrlOrStorage = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const reposParam = urlParams.get('repos')
    
    if (reposParam) {
      const repos = reposParam.split(',').map(repo => decodeURIComponent(repo))
      this.setState({ repos }, () => {
        this.updateUrl()
        localStorage.setItem("repos", JSON.stringify(repos))
      })
    } else {
      try {
        const savedRepos = JSON.parse(localStorage.getItem("repos"))
        if (savedRepos) {
          this.setState({ repos: savedRepos, deleteRepo: '' }, this.updateUrl)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  updateUrl = () => {
    const { repos } = this.state
    const reposParam = repos.map(repo => encodeURIComponent(repo)).join(',')
    const newUrl = reposParam ? `${window.location.pathname}?repos=${reposParam}` : window.location.pathname
    window.history.pushState({}, '', newUrl)
  }

  deleteRepo = index => {
    const { repos } = this.state
    const deleteRepo = repos[index]
    repos.splice(index, 1)
    this.setState({ repos: [...repos], deleteRepo }, () => {
      this.updateUrl()
      localStorage.setItem("repos", JSON.stringify(repos))
    })
  }

  addRepo = repo => {
    const { repos } = this.state
    if (repos.includes(repo)) {
      message.error(`${repo} is already added`)
    } else {
      this.setState({ repos: [...repos, repo], deleteRepo: '' }, () => {
        this.updateUrl()
        localStorage.setItem("repos", JSON.stringify(this.state.repos))
      })
    }
  }

  handleInputChange = input => {
    this.setState({ input })
    this.search(input, suggestions => this.setState({ suggestions }))
  }

  renderDataSections = () => {
    const { repos, deleteRepo } = this.state
    return Object.values(TYPES).map(type => (
      <DataSection
        key={type}
        type={type}
        repos={repos}
        deleteRepo={deleteRepo}
      />
    ))
  }

  render() {
    const { repos, input, suggestions, testingRepo, deleteRepo } = this.state

    return (
      <div className="app-container">
        <Header
          input={input}
          testingRepo={testingRepo}
          suggestions={suggestions}
          repos={repos}
          onInputChange={this.handleInputChange}
          onAddRepo={this.addRepo}
          onDeleteRepo={this.deleteRepo}
        />
        <div className="content-container">
          <div className="content">
            {this.renderDataSections()}
          </div>
        </div>
        <footer className="footer" />
      </div>
    )
  }
}

GithubStatistics.propTypes = {
  updateState: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  updateState: (state, data) => dispatch(updateState(state, data)),
})

export default connect(null, mapDispatchToProps)(GithubStatistics)
