import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GithubStatistics from './GithubStatistics';
import DonateOption1 from '../pages/DonateOption1';
import DonateOption2 from '../pages/DonateOption2';
import DonateOption3 from '../pages/DonateOption3';
import '../css/App.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GithubStatistics />} />
        <Route path="/donate/option1" element={<DonateOption1 />} />
        <Route path="/donate/option2" element={<DonateOption2 />} />
        <Route path="/donate/option3" element={<DonateOption3 />} />
      </Routes>
    </Router>
  );
};

export default App;
