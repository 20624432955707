import React from 'react';
import { Row, Col, Button, Tooltip, Select, Dropdown, Menu, Tag } from 'antd';
import { Link } from 'react-router-dom';
import COLORS from './sections/Colors';

const Header = ({ input, testingRepo, suggestions, repos, onInputChange, onAddRepo, onDeleteRepo }) => {
  const renderTags = () => {
    return repos.map((repo, index) => (
      <Tag key={`tag${repo}`} color={COLORS[index]} closable onClose={() => onDeleteRepo(index)}>
        <a target="_blank" rel="noopener noreferrer" href={`https://github.com/${repo}`}>{repo}</a>
      </Tag>
    ));
  };

  const renderHeaderInput = () => {
    const inputEmpty = input === undefined;
    const repoExisted = repos.includes(input);
    const hintMessage = repoExisted ? 'Repository already added' : (inputEmpty ? 'Empty' : '');
    const disabled = inputEmpty || repoExisted;

    const donationMenu = (
      <Menu>
        <Menu.Item key="1">
          <Link to="/donate/option1">Donation Option 1</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/donate/option2">Donation Option 2</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/donate/option3">Donation Option 3</Link>
        </Menu.Item>
      </Menu>
    );

    return (
      <React.Fragment>
        <Select
          className="header-input"
          value={input}
          placeholder="{user|org} / {repo name}"
          defaultActiveFirstOption={false}
          onChange={onInputChange}
          onSearch={onInputChange}
          notFoundContent={null}
          showArrow={false}
          filterOption={false}
          showSearch
        >
          {suggestions.map(repo => (
            <Select.Option key={`suggestion-${repo}`} value={repo}>{repo}</Select.Option>
          ))}
        </Select>
        <Tooltip title={hintMessage}>
          <Button
            icon="plus"
            type="primary"
            loading={testingRepo}
            disabled={disabled}
            onClick={() => onAddRepo(input)}
          />
        </Tooltip>
        <Dropdown overlay={donationMenu} placement="bottomRight">
          <Button type="primary" icon="heart" style={{ marginLeft: '8px' }}>
            Donate
          </Button>
        </Dropdown>
      </React.Fragment>
    );
  };

  return (
    <header className="header">
      <Row type="flex" align="middle">
        <Col className="header-section">
          <Link to="/" className="header-title">
            GitHub Repo Stats Compare
          </Link>
        </Col>
        <Col className="header-section flex-center">
          {renderHeaderInput()}
        </Col>
        <Col className="header-section flex-center-left">
          {renderTags()}
        </Col>
      </Row>
    </header>
  );
};

export default Header;
