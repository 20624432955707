import React from 'react';
import { Button, Card, Layout } from 'antd';
import { Link } from 'react-router-dom';

const { Header, Content } = Layout;

const DonateOption2 = () => {
  return (
    <Layout>
      <Header style={{ background: '#fff', padding: '0 16px' }}>
        <Link to="/">Back to Home</Link>
      </Header>
      <Content className="min-h-screen flex items-center justify-center bg-gray-100">
        <Card
          title="Donation Option 2"
          style={{ width: 300 }}
        >
          <p>Become a monthly supporter</p>
          <p>Your monthly donation provides sustainable support for our ongoing projects.</p>
          <Button type="primary" onClick={() => alert("Thank you for your monthly support!")}>
            Donate $5/month
          </Button>
        </Card>
      </Content>
    </Layout>
  );
};

export default DonateOption2;
